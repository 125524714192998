// @flow
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/auth'

const firebaseConfig = {
  apiKey: "AIzaSyAuQArpFSnreTVlfSKn3rYqZ70402ScL-U",
  authDomain: "codingprobs.firebaseapp.com",
  projectId: "codingprobs",
  storageBucket: "codingprobs.appspot.com",
  messagingSenderId: "304090666694",
  appId: "1:304090666694:web:dcbb30b8aefc5ee43ca9c7",
  measurementId: "G-Y413PQZPZ7"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const auth: any = firebase.auth();
const database = firebase.database()
const googleProvider = new firebase.auth.GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await auth.signInWithPopup(googleProvider);
    const user = res.user;
    const { isNewUser } = res.additionalUserInfo

    if (isNewUser) {
      const {uid, email, displayName, photoURL} = user;
      await database.ref(`/users/${uid}`).set({uid, email, displayName, photoURL})
    }
  } catch (err) {
    alert(err.message);
  }
};

const logout = () => {
  auth.signOut();
};

const updateUserCode = (uid : string, problemId : string, code : string): Promise<any> => {
  return database.ref(`/users/${uid}/code/${problemId}`).set({code})
}

const getUserCode = (uid: string, problemId: string) : Promise<any> => {
  return database.ref(`/users/${uid}/code/${problemId}`).once('value')
}

const getUserSolvedProblems = (uid: string) : Promise<any> => {
  return database.ref(`/users/${uid}/solvedProblems`).once('value')
}

const setProblemSolved = (uid: string, problemId: string) : Promise<any> => {
  return database.ref(`/users/${uid}/solvedProblems/${problemId}`).set(true)
}

export {
  auth,
  signInWithGoogle,
  logout,
  updateUserCode,
  getUserCode,
  getUserSolvedProblems,
  setProblemSolved
};