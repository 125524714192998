// @flow
import * as React from 'react'
import { Box, Button, HStack, Text} from "@chakra-ui/react"
import { useRecoilState, useRecoilValue } from 'recoil'
import { WalkthroughStepState, WalkthroughStepsState } from "../../state/WalkthroughState";
import { useCallback, useMemo } from 'react'

export default function WalkthroughConsoleHeader({
    showConsole,
    hideConsole
}: {
    showConsole: () => void,
    hideConsole: () => void
}) : React.Element<typeof Box> {
    const [walkthroughStep, setWalkthroughStep] = useRecoilState(WalkthroughStepState)
    const walkthroughSteps = useRecoilValue(WalkthroughStepsState)

    const startWalkthrough = useCallback(() => {
        setWalkthroughStep(0)
        showConsole()
    }, [setWalkthroughStep, showConsole])

    const endWalkthrough = useCallback(() => {
        setWalkthroughStep(-1)
        hideConsole()
    }, [setWalkthroughStep, hideConsole])

    const onPreviousClicked = useCallback(() => {
        setWalkthroughStep(prev => prev - 1)
    }, [setWalkthroughStep])

    const onNextClicked = useCallback(() => {
        setWalkthroughStep(prev => prev + 1)
    }, [setWalkthroughStep])

    const walkthroughStarted = useMemo(() => {
        return walkthroughStep >= 0
    }, [walkthroughStep])

    const startEndButtonDisabled = useMemo(() => {
        return walkthroughSteps.length === 0
    }, [walkthroughSteps])

    const previousButtonDisabled = useMemo(() => {
        return walkthroughStep === 0
    }, [walkthroughStep])

    const nextButtonDisabled = useMemo(() => {
        return walkthroughStep >= walkthroughSteps.length - 1
    }, [walkthroughStep, walkthroughSteps])

    return (
        <Box display="flex" justifyContent="space-between">
            <HStack>
                <Button 
                    colorScheme="yellow" 
                    onClick={walkthroughStarted ? endWalkthrough : startWalkthrough}
                    isDisabled={startEndButtonDisabled}
                > 
                    {
                        walkthroughStarted ? 
                        'End Walkthrough' : 
                        'Start Walkthrough'
                    }
                </Button>
                {
                    walkthroughStarted ?
                    (
                        <Box display="flex" gap={10}>
                            <HStack>
                                <Button 
                                    onClick={onPreviousClicked}
                                    isDisabled={previousButtonDisabled}
                                >
                                    Previous
                                </Button>
                                <Button 
                                    onClick={onNextClicked}
                                    isDisabled={nextButtonDisabled}
                                >
                                    Next
                                </Button>
                            </HStack>
                        </Box>
                    ) :
                    null
                }
            </HStack>
            {
                walkthroughStep >= 0 ?
                <Text>{`${walkthroughStep + 1} / ${walkthroughSteps.length}`}</Text> :
                null
            }
        </Box>
    )
}