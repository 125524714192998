
import {Box, Heading, HStack, VStack, Text, Image, Button} from '@chakra-ui/react'
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, signInWithGoogle } from '../auth/firebase';
import checkMark from '../assets/check-mark.png'
import { useEffect } from 'react';

export default function Home() {
    const [user, loading, error] = useAuthState(auth);

    useEffect(() => {
        if (loading) {
            return
        }

        if (user) {
            console.log({user})
        }

        if (error) {
            console.log({error})
        }
    }, [loading, user, error])

    const numberOfProblems = 40
    const features = [
        '7 problem categories, from arrays to graphs',
        'Coding workspace',
        'Code solutions',
        'Code walkthroughs',
        'Explanatory slideshows'
    ]

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            justifyContent="center" 
            width="100%" 
            height="100%" 
            alignItems="center"
        >
            <Heading size="4xl">CodingProbs</Heading>
            <Heading>{`${numberOfProblems} coding problems in Python to prepare for technical interviews`}</Heading>
            <VStack alignItems="flex-start" marginTop={10}>
                {
                    features.map((feature) => {
                        return (
                            <HStack>
                                <Image src={checkMark} height={30}/>
                                <Text>{feature}</Text>
                            </HStack>
                        )
                    })
                }
            </VStack>
            {
                user ?
                null :
                <Button onClick={signInWithGoogle}> Sign In With Google </Button>
            }
        </Box>
    )
}