// @flow
import * as React from 'react'
import { Box, Image } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil'
import { SlideshowStepState, SlideshowState } from "../state/SlideshowState";

export default function SlideshowViewer(): React.Element<typeof Box> {
    const step = useRecoilValue(SlideshowStepState)
    const slideshow = useRecoilValue(SlideshowState)

    const content = useMemo(() => {
        if (slideshow && step >= 0 && step < slideshow.length) {
            return <Image src={slideshow[step].imageUrl} alt=""/>
        } else {
            return <h1>No image</h1>
        }
    }, [slideshow, step])

    return (
        <Box flex={10} overflowY="hidden" display="flex" padding={10}>
            <Box 
                display="flex" 
                p={30} 
                height="100%"
                justifyContent="center"
            >
                {content}
            </Box>
        </Box>
    )
}