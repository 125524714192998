//@flow
import * as React from "react";
import { Box, VStack, StackDivider } from "@chakra-ui/react"
import { useRecoilState, useRecoilValue } from "recoil"
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, getUserSolvedProblems } from '../auth/firebase';
import { ProblemsState } from "../state/ProblemsState"
import { SolvedProblems } from "../state/ProfileState";
import LeftSideMenuItem from "./LeftSideMenuItem"
import LeftSideMenuOptions from "./LeftSideMenuOptions"
import { useEffect } from "react";


const styles = {
    container: {
        height: '100%',
        width: '100%',
        overflowY: 'scroll',

    },
    divider: {
        margin: 0
    }
}

export default function LeftSideMenu(): React.Element<typeof Box> {
    const problems = useRecoilValue(ProblemsState)
    const [user] = useAuthState(auth);
    const [solvedProblems, setSolvedProblems] = useRecoilState(SolvedProblems)

    useEffect(() => {
        if (user) {
            getUserSolvedProblems(user.uid).then((result) => {
                const value = result.val() || {}
                const sp = Object.keys(value)
                setSolvedProblems(sp)
            })
        } else {
            setSolvedProblems([])
        }
    }, [user, setSolvedProblems])

    return (
        <Box 
            style={{height: '100%', width: '20vw', minWidth: '250px'}} 
            display="flex" 
            flexDirection="column" 
            borderEnd={'1px solid'} 
            borderEndColor={"lightgray"}
        >
            <LeftSideMenuOptions />
            <Box style={styles.container}>
                <VStack 
                    divider={<StackDivider style={styles.divider} borderColor="gray.200"/>}
                >
                    {problems.map(
                        problem => 
                            <LeftSideMenuItem 
                                id={problem.pathName}
                                name={problem.name} 
                                difficulty={problem.difficulty} 
                                category={problem.category}
                                solved={solvedProblems.includes(problem.id)}
                            />)}
                </VStack>
            </Box>
        </Box>
    )
}