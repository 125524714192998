// @flow
import * as React from 'react'
import { Box } from "@chakra-ui/react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSpring, animated } from 'react-spring'
import EditorConsoleHeader from "./console/EditorConsoleHeader"
import EditorConsoleContent from "./console/EditorConsoleContent"
import WalkthroughConsoleHeader from "./console/WalkthroughConsoleHeader"
import WalkthroughConsoleContent from "./console/WalkthroughConsoleContent"
import SlideshowConsoleHeader from "./console/SlideshowConsoleHeader"
import SlideshowConsoleContent from "./console/SlideshowConsoleContent"

// $FlowIgnore
const AnimatedBox = animated(Box)

const RUN_SECTION = 'RUN'
const INITIAL_CONSOLE_HEIGHT = "70px"
const SHOWN_CONSOLE_HEIGHT = "300px"

export default function Console({tabIndex}: {tabIndex : number}): React.Element<typeof AnimatedBox> {
    const [isHidden, setIsHidden] = useState(true)
    const [section, setSection] = useState(RUN_SECTION)
    const [style, api] = useSpring({height: INITIAL_CONSOLE_HEIGHT}, [])

    useEffect(() => {
        if (tabIndex === 3 || !isHidden ) {
            api.start({height: SHOWN_CONSOLE_HEIGHT})
        } else if (isHidden) {
            api.start({height: INITIAL_CONSOLE_HEIGHT})
        }
    }, [isHidden, api, tabIndex])

    const showConsole = useCallback(() => {
        setIsHidden(false)
    }, [])

    const hideConsole = useCallback(() => {
        setIsHidden(true)
    }, [])

    const content = useMemo(() => {
        if (tabIndex === 0 || tabIndex === 1) {
            return (
                <>
                    <EditorConsoleHeader 
                        showConsole={showConsole}
                        hideConsole={hideConsole}
                        isHidden={isHidden}
                        section={section}
                        setSection={setSection}
                    />
                    <EditorConsoleContent section={section} />
                </>
            )
        } else if (tabIndex === 2) {
            return (
                <>
                    <WalkthroughConsoleHeader   
                        showConsole={showConsole}
                        hideConsole={hideConsole}
                    />
                    <WalkthroughConsoleContent />
                </>
            )
        } else if (tabIndex === 3) {
            return (
                <>
                    <SlideshowConsoleHeader />
                    <SlideshowConsoleContent />
                </>
            )
        }
    }, [
        tabIndex, 
        hideConsole, 
        showConsole,
        isHidden, 
        section,
        setSection
    ])

    return (
        <AnimatedBox 
            width="100%"
            style={style}
            display="flex"
            flexDirection="column"
            p={5}
            borderTopWidth={1}
            borderTopRadius={20}
            overflowY="hidden"
        >
            {content}
        </AnimatedBox>
    )
}