// @flow
import * as React from 'react'
import { Box, Text } from "@chakra-ui/react";
import { useRecoilValue } from 'recoil'
import { WalkthroughStepState, WalkthroughStepsState } from "../../state/WalkthroughState";
import { useMemo } from 'react'

export default function WalkthroughConsoleContent(): React.Element<typeof Box> {
    const walkthroughStep = useRecoilValue(WalkthroughStepState)
    const walkthroughSteps = useRecoilValue(WalkthroughStepsState)
    const step = useMemo(() => {
        if (walkthroughStep >= 0 && walkthroughStep < walkthroughSteps.length) {
            return walkthroughSteps[walkthroughStep].text
        }
        return ''
    }, [walkthroughStep, walkthroughSteps])

    return (
        <Box>
            <Text fontSize="2xl" >{step}</Text>
        </Box>
    )
}