// @flow
import * as React from 'react'
import { Box, useColorMode } from "@chakra-ui/react";
import MonacoEditor from "@monaco-editor/react";
import { useRecoilValue } from 'recoil'
import { EditorFontSizeState } from '../state/SettingsState';
import { WalkthroughStepState, WalkthroughStepsState } from "../state/WalkthroughState";
import { useState, useEffect } from "react";

import '../styles/WalkthroughEditor.css'

export default function WalkthroughEditor({value} : {value: ?string}): React.Element<typeof Box> {
    const { colorMode } = useColorMode()
    const walkthroughStep =  useRecoilValue(WalkthroughStepState)
    const walkthroughSteps = useRecoilValue(WalkthroughStepsState)
    const fontSize = useRecoilValue(EditorFontSizeState)
    const [codeEditor, setCodeEditor] = useState(null)
    const [monacoObject, setMonacoObject] = useState(null)
    const [, setCodeDecorations] = useState([])

    useEffect(() => {
        if (!monacoObject || !codeEditor || walkthroughSteps.length === 0 ) {
            return
        }
        if (walkthroughStep < 0) {
            setCodeDecorations((prevCodeDecorations) => codeEditor.deltaDecorations(
              prevCodeDecorations,
              [
                  {
                      range: new monacoObject.Range(1,1,1,1),
                      options: {
                          inlineClassName: "myInlineDecoration",
                      },
                  },
              ]
            ));
            return
          }
      
        const firstStep = walkthroughSteps[walkthroughStep]
        const range = new monacoObject.Range(firstStep.firstLine, 1, firstStep.lastLine + 1, 1);
        setCodeDecorations((prevCodeDecorations) => codeEditor.deltaDecorations(
            prevCodeDecorations,
            [
                {
                    range,
                    options: {
                        inlineClassName: "myInlineDecoration",
                    },
                },
            ]
        ));
    }, [monacoObject, codeEditor, walkthroughStep, walkthroughSteps])

    const onEditorMount = (editor, monaco) => {
        setCodeEditor(editor)
        setMonacoObject(monaco)
    }

    return (
        <Box flex={10} overflowY="hidden">
            <MonacoEditor 
                width="100%" 
                height="100%" 
                defaultLanguage="python" 
                defaultValue={value}
                theme={colorMode === "dark" ? "vs-dark" : "light"}
                value={value}
                onMount={onEditorMount}
                options={{
                    readOnly: true,
                    fontSize
                }}
            />
        </Box>
    )
}