// @flow
import * as React from 'react'
import { Tabs, TabList, Tab } from "@chakra-ui/react"

export default function DevEnvironmentTabs({
    setTabIndex
}: {
    setTabIndex: (number) => void
}): React.Element<typeof Tabs> {
    return (
        <Tabs variant="enclosed" onChange={setTabIndex}>
            <TabList>
                <Tab>Your Code</Tab>
                <Tab>Tests</Tab>
                <Tab>Code Walkthrough</Tab>
                <Tab>Slideshow</Tab>
            </TabList>
        </Tabs>
    )
}