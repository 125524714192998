
export const difficulties = [
    'Easy',
    'Medium',
    'Hard'
]

export const categories = [
    'Arrays',
    'Strings',
    'Linked Lists',
    'Trees',
    'Dynamic Programming',
    'Backtracking',
    'Graphs'
]

export const problems = [
    {
        "id": "5e8b6e7cc8285c0915ee9ac0",
        "name": "Three Sum",
        "pathName": "Three-Sum",
        "difficulty": "Easy",
        "category": "Arrays"
    },
    {
        "id": "5e95edda3d6d912300d58935",
        "name": "Two Sum",
        "pathName": "Two-Sum",
        "difficulty": "Easy",
        "category": "Arrays"
    },
    {
        "id": "5e97274a6430db174e6de88e",
        "name": "Group Anagrams",
        "pathName": "Group-Anagrams",
        "difficulty": "Medium",
        "category": "Strings"
    },
    {
        "id": "5e9737693efb5b716a18cc1d",
        "name": "Longest Common Prefix",
        "pathName": "Longest-Common-Prefix",
        "difficulty": "Easy",
        "category": "Strings"
    },
    {
        "id": "5e9745724ce3788e5e259d7a",
        "name": "Three Sum Closest",
        "pathName": "Three-Sum-Closest",
        "difficulty": "Medium",
        "category": "Arrays"
    },
    {
        "id": "5e975b9ee74c36502dac1f1f",
        "name": "Maximum Subarray",
        "pathName": "Maximum-Subarray",
        "difficulty": "Easy",
        "category": "Dynamic Programming"
    },
    {
        "id": "5e97c90c913e21209b66614c",
        "name": "Longest Increasing Subsequence",
        "pathName": "Longest-Increasing-Subsequence",
        "difficulty": "Medium",
        "category": "Dynamic Programming"
    },
    {
        "id": "5e99d42999860b7385a09cb1",
        "name": "Longest Palindromic Substring",
        "pathName": "Longest-Palindromic-Substring",
        "difficulty": "Medium",
        "category": "Dynamic Programming"
    },
    {
        "id": "5e99e42d6778b1cd5a3054f2",
        "name": "Minimum Edit Distance",
        "pathName": "Minimum-Edit-Distance",
        "difficulty": "Hard",
        "category": "Dynamic Programming"
    },
    {
        "id": "5e9a04bb0cac01a9b57fa67d",
        "name": "Reverse Linked List",
        "pathName": "Reverse-Linked-List",
        "difficulty": "Easy",
        "category": "Linked Lists"
    },
    {
        "id": "5e9c5fcffaa1619dd9b292bb",
        "name": "Linked List Cycle",
        "pathName": "Linked-List-Cycle",
        "difficulty": "Easy",
        "category": "Linked Lists"
    },
    {
        "id": "5e9dc47babadb84c619d2e25",
        "name": "Swap Nodes in Pairs",
        "pathName": "Swap-Nodes-in-Pairs",
        "difficulty": "Medium",
        "category": "Linked Lists"
    },
    {
        "id": "5e9dc974d6ffe1ac18d9a10d",
        "name": "Maximum Depth of Binary Tree",
        "pathName": "Maximum-Depth-of-Binary-Tree",
        "difficulty": "Easy",
        "category": "Trees"
    },
    {
        "id": "5e9e016558d73379d8e0d503",
        "name": "Convert Sorted Array To Binary Search Tree",
        "pathName": "Convert-Sorted-Array-To-Binary-Search-Tree",
        "difficulty": "Easy",
        "category": "Trees"
    },
    {
        "id": "5e9e12d95495da4dc68c3b37",
        "name": "Binary Tree Maximum Path Sum",
        "pathName": "Binary-Tree-Maximum-Path-Sum",
        "difficulty": "Hard",
        "category": "Trees"
    },
    {
        "id": "5ea0566842995132334084ae",
        "name": "Number of Islands",
        "pathName": "Number-of-Islands",
        "difficulty": "Medium",
        "category": "Graphs"
    },
    {
        "id": "5ea069a7d195daaee2cccb9b",
        "name": "Serialize and Deserialize Binary Tree",
        "pathName": "Serialize-and-Deserialize-Binary-Tree",
        "difficulty": "Hard",
        "category": "Trees"
    },
    {
        "id": "5ea09a64f50e22026a88b75e",
        "name": "Add Two Numbers",
        "pathName": "Add-Two-Numbers",
        "difficulty": "Medium",
        "category": "Linked Lists"
    },
    {
        "id": "5ea2f8e83dc1d2601211ff5c",
        "name": "Coin Change",
        "pathName": "Coin-Change",
        "difficulty": "Medium",
        "category": "Dynamic Programming"
    },
    {
        "id": "5ea33082cb2890fe7c7f8849",
        "name": "Regular Expression Matching",
        "pathName": "Regular-Expression-Matching",
        "difficulty": "Hard",
        "category": "Dynamic Programming"
    },
    {
        "id": "5ea353c30770d53213cf4ffe",
        "name": "Clone Graph",
        "pathName": "Clone-Graph",
        "difficulty": "Medium",
        "category": "Graphs"
    },
    {
        "id": "5ea6f5ef031385cf547aa5c0",
        "name": "Flatten Binary Tree to Linked List",
        "pathName": "Flatten-Binary-Tree-to-Linked-List",
        "difficulty": "Medium",
        "category": "Trees"
    },
    {
        "id": "5ea7199efe633c5c8dbc5384",
        "name": "Best Time to Buy and Sell Stock",
        "pathName": "Best-Time-to-Buy-and-Sell-Stock",
        "difficulty": "Medium",
        "category": "Arrays"
    },
    {
        "id": "5ea75222b9f93b36e20648bd",
        "name": "Maximum Product Subarray",
        "pathName": "Maximum-Product-Subarray",
        "difficulty": "Medium",
        "category": "Arrays"
    },
    {
        "id": "5ea76de47be85c9036c66ad6",
        "name": "Friend Circles",
        "pathName": "Friend-Circles",
        "difficulty": "Medium",
        "category": "Graphs"
    },
    {
        "id": "5ea9aac97422e8d836828f89",
        "name": "Letter Case Permutation",
        "pathName": "Letter-Case-Permutation",
        "difficulty": "Easy",
        "category": "Backtracking"
    },
    {
        "id": "5ea9b4bc8f1c8fbb9401bb6c",
        "name": "Subsets",
        "pathName": "Subsets",
        "difficulty": "Medium",
        "category": "Backtracking"
    },
    {
        "id": "5eb32031d30ae011e90f3e9d",
        "name": "Product of Array Except Self",
        "pathName": "Product-of-Array-Except-Self",
        "difficulty": "Medium",
        "category": "Arrays"
    },
    {
        "id": "61dc80dd5777fe46bbfe51bf",
        "name": "Non-decreasing or Non-increasing",
        "pathName": "Non-decreasing-or-Non-increasing",
        "difficulty": "Medium",
        "category": "Arrays"
    },
    {
        "id": "61df02c5c5189034ddf90149",
        "name": "Valid Parentheses",
        "pathName": "Valid-Parentheses",
        "difficulty": "Medium",
        "category": "Strings"
    },
    {
        "id": "61e841bad1ebe82e8b5eca02",
        "name": "Palindrome Linked List",
        "pathName": "Palindrome-Linked-List",
        "difficulty": "Medium",
        "category": "Linked Lists"
    },
    {
        "id": "61eada0589880ee767537bff",
        "name": "Lowest Common Ancestor",
        "pathName": "Lowest-Common-Ancestor",
        "difficulty": "Hard",
        "category": "Trees"
    },
    {
        "id": "61eed1d90800e45f94fb34fb",
        "name": "All Nodes Distance K",
        "pathName": "All-Nodes-Distance-K",
        "difficulty": "Hard",
        "category": "Trees"
    },
    {
        "id": "61f1717dce3e085251e552f1",
        "name": "Study Plan",
        "pathName": "Study-Plan",
        "difficulty": "Hard",
        "category": "Graphs"
    },
    {
        "id": "61f81289059a78fc8da58079",
        "name": "Knapsack",
        "pathName": "Knapsack",
        "difficulty": "Hard",
        "category": "Dynamic Programming"
    },
    {
        "id": "61fbfbf1a89218f64afa0c3d",
        "name": "Range Search",
        "pathName": "Range-Search",
        "difficulty": "Hard",
        "category": "Arrays"
    },
    {
        "id": "62017cdae9e1b4cc1445ea5a",
        "name": "Find Kth Largest in BST",
        "pathName": "Find-Kth-Largest-in-BST",
        "difficulty": "Medium",
        "category": "Trees"
    },
    {
        "id": "620575a02261e06d76512f63",
        "name": "Letter Soup",
        "pathName": "Letter-Soup",
        "difficulty": "Hard",
        "category": "Strings"
    },
    {
        "id": "620c3a71a29f9c5368370158",
        "name": "Word Search",
        "pathName": "Word-Search",
        "difficulty": "Hard",
        "category": "Strings"
    },
    {
        "id": "620ecc596195430fac249a21",
        "name": "Movie Scheduling",
        "pathName": "Movie-Scheduling",
        "difficulty": "Medium",
        "category": "Arrays"
    }
]

export const getProblemFromPathName = (pathName) => {
   return problems.find(problem => problem.pathName === pathName)
}
