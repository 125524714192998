// @flow
import * as React from 'react'
import { Box, Badge, VStack, useColorMode, Image, Text } from "@chakra-ui/react"
import { useHistory } from "react-router-dom";
import { useResetRecoilState } from 'recoil'
import { CodeRunState, CodeTestState} from '../state/CodeState'
import { TestsState } from '../state/TestsState'
import checkMark from '../assets/check-mark.png'
import DifficultyBadge from "./DifficultyBadge";

export default function LeftSideMenuItem({
    id,
    name,
    category,
    solved,
    difficulty
}: {
    id: string,
    name: string,
    category: string,
    solved: boolean,
    difficulty: "Easy" | "Medium" | "Hard"
}): React.Element<typeof Box> {
    const {colorMode} = useColorMode()
    const history = useHistory()
    const resetCodeRunState = useResetRecoilState(CodeRunState)
    const resetCodeTestState = useResetRecoilState(CodeTestState)
    const resetTestsState = useResetRecoilState(TestsState)

    const onClick = () => {
        resetCodeRunState()
        resetCodeTestState()
        resetTestsState()
        history.push(`/${id}`) // id is the pathname
    }

    return (
        <Box 
            className={colorMode === "dark" ? "HoverItemDark" : "HoverItemLight"}
            p={4} 
            paddingTop={4}
            paddingBottom={4}
            width="100%"
            height="100px" 
            display="flex" 
            alignItems="center" 
            justifyContent="space-between"
            cursor="pointer"
            onClick={onClick}
        >
            <VStack alignItems="flex-start">
                <Box display="flex" alignItems="center">
                    {
                        solved ?
                        <Image src={checkMark} height={5} marginRight={2}/> :
                        null
                    }
                    <Text>{name}</Text>
                </Box>
                <Badge>{category}</Badge>
            </VStack>
            <DifficultyBadge difficulty={difficulty} />
    </Box>
    )
}