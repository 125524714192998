import { atom } from 'recoil'

export const SlideshowStepState = atom({
    key: 'SlideshowStepState',
    default: 0
})

export const SlideshowState = atom({
    key: 'SlideshowState',
    default: []
})