// @flow
import * as React from 'react'
import { Box } from "@chakra-ui/react";
import {
    useParams
  } from "react-router-dom";
import debounce from 'lodash.debounce';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, updateUserCode } from '../auth/firebase';
import {useRecoilValueLoadable, useRecoilState} from 'recoil'
import DevEnvironmentTabs from "./DevEnvironmentTabs";
import useDevEnvironment from "../hooks/DevEnvironmentHook";
import { SelectedProblemState } from "../state/ProblemsState";
import { CodeSolutionState } from "../state/CodeState";
import Editor from "./Editor";
import WalkthroughEditor from "./WalkthroughEditor";
import SlideshowViewer from "./SlideshowViewer";
import Console from "./Console";
import { getProblemFromPathName } from '../data/problems';
import { useCallback, useEffect, useMemo, useState } from "react";

export default function DevEnvironment(): React.Element<typeof Box> {
    const [tabIndex, setTabIndex] = useState(0)
    const [user, loading, error] = useAuthState(auth);
    const { problemPath } = useParams()
    // $FlowIgnore[cannot-spread-inexact]
    const problemState = useRecoilValueLoadable(SelectedProblemState({user, pathName: problemPath}))
    const [codeSolution, setCodeSolution] = useRecoilState(CodeSolutionState)
    const setEnvironment = useDevEnvironment()

    useEffect(() => {
        setEnvironment(problemState)
    }, [problemState,setEnvironment])

    const onUpdateUserCode = useCallback((code) => {
        const problem = getProblemFromPathName(problemPath)
        user && updateUserCode(user?.uid, problem.id, code)
    }, [user, problemPath])

    const debounceUpdateUserCode = useMemo(
        () => debounce(onUpdateUserCode, 300), 
    [onUpdateUserCode])

    const onChangeEditorValue = useCallback((code) => {
        // set state of code solution
        setCodeSolution(code)
        if (user) {
            // set code in firebase
            debounceUpdateUserCode(code)
        }
    }, [setCodeSolution, user, debounceUpdateUserCode])

    const getEditorValue = useCallback(() => {
        let editorValue;
        if (tabIndex === 0) {
            editorValue = codeSolution ?? problemState.contents.startCode
        } else if (tabIndex === 1) {
            editorValue = problemState.contents.testCode
        } else if (tabIndex === 2) {
            editorValue = problemState.contents.solution
        }
        return editorValue
    }, [tabIndex, problemState, codeSolution])

    const content = useMemo(() => {
        if (problemState.state === "loading" || loading) {
            return <h1> Loading </h1>
        } else if (problemState.state === "hasError" || error) {
            console.log({problemState})
            return <h1> Error </h1>
        }

        let editorValue = getEditorValue()
        
        if (tabIndex === 0 || tabIndex === 1) {
            return <Editor value={editorValue} onChange={onChangeEditorValue} readOnly={tabIndex === 0 ? false : true}/>
        } else if (tabIndex === 2) {
            return <WalkthroughEditor value={editorValue} />
        } else if (tabIndex === 3) {
            return <SlideshowViewer />
        }

    }, [tabIndex, problemState, onChangeEditorValue, loading, error, getEditorValue])
    
    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column" paddingTop={1} overflowY="hidden">
            <DevEnvironmentTabs setTabIndex={setTabIndex} />
            <Box 
                display="flex" 
                flexDirection="column" 
                w={"100%"} 
                height={"100%"}
                justifyContent="space-between"
            >
                {content}
                <Console tabIndex={tabIndex}/>
            </Box>
        </Box>
    )
}