// @flow
import * as React from 'react'
import { HStack, Box, Popover, PopoverTrigger, PopoverContent, PopoverBody } from "@chakra-ui/react";
import { useMemo } from "react";
import { useRecoilValue } from 'recoil'
import { TestsState } from '../state/TestsState'


function TestResult({
    testNumber, 
    testStatus, 
    testDescription
} : 
{
    testNumber: number,
    testStatus: "PASS" | "FAIL" | "ERROR",
    testDescription: string
}

) {

    const testColor = useMemo(() => {
        if (testStatus === "PASS") {
            return "green.400"
        } else if (testStatus === "FAIL") {
            return "red.400"
        } else if (testStatus === "ERROR") {
            return "purple.400"
        }
        return "gray.400"
    }, [testStatus])

    return (
        <Popover>
            <PopoverTrigger>
                <Box 
                    borderRadius="50%" 
                    bgColor={testColor}
                    display="flex" 
                    alignItems="center" 
                    width={10}
                    height={10}
                    justifyContent="center"
                    cursor="pointer"
                    textColor="white"
                    fontWeight="bold"
                >
                    {testNumber}
                </Box>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverBody>
                    {testDescription}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )

}

export default function TestResults(): React.Element<typeof HStack> {
    const testState = useRecoilValue(TestsState)

    return (
        <HStack spacing={3}>
            {
                testState.map((testResult, index) => 
                    <TestResult 
                        testNumber={index + 1}
                        testStatus={testResult}
                        testDescription={testResult}/>)
            }
        </HStack>
    )
}