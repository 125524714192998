// @flow
import * as React from 'react'
import { 
    Box, 
    Heading, 
    IconButton, 
    Icon,
    HStack,
    useDisclosure,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { AiOutlineUser, AiOutlineSetting } from 'react-icons/ai'
import ProfileModal from "./ProfileModal";
import SettingsModal from "./SettingsModal";

import { useState } from "react";

export default function LeftSideMenuOptions(): React.Element<typeof Box> {
    const history = useHistory()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [settingsOpen, setSettingsOpen] = useState(false)

    const onLogoClicked = () => {
        history.push('/')
    }

    return (
        <Box p={3} borderBottomStyle="double" borderBottomWidth={4}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Heading onClick={onLogoClicked} cursor="pointer">CPs</Heading>
                <HStack>
                    <IconButton 
                        icon={<Icon as={AiOutlineUser}/>} 
                        variant="ghost" 
                        onClick={onOpen}
                    />
                    <IconButton 
                        icon={<Icon as={AiOutlineSetting}/>} 
                        variant="ghost" 
                        onClick={() => setSettingsOpen(true)}
                    />
                </HStack>
            </Box>
            <ProfileModal isOpen={isOpen} onClose={onClose}/>
            <SettingsModal isOpen={settingsOpen} onClose={() => setSettingsOpen(false)}/>
        </Box>
    )
}