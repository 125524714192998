import { atom } from 'recoil'

export const WalkthroughStepState = atom({
    key: 'WalkthroughStepState',
    default: -1
})

export const WalkthroughStepsState = atom({
    key: 'WalkthroughSteps',
    default: []
})