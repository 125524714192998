import { atom, selector, selectorFamily } from 'recoil'
import axios from 'axios'
import { getUserCode } from '../auth/firebase'
import { difficulties, categories, problems } from '../data/problems'

export const orderByOptions = ['Random', 'Difficulty', 'Category']

export const ProblemsFiltersState = atom({
    key: "ProblemsFiltersState",
    default: {
        difficulties,
        categories,
        orderBy: localStorage.getItem('orderBy') ?? 'Random'
    }
})

const difficultySortValues = {
    'Easy': 0,
    'Medium': 1,
    'Hard': 2
}

const categorySortValues = {
    'Arrays': 0,
    'Strings': 1,
    'Linked Lists': 2,
    'Trees': 3,
    'Dynamic Programming': 4,
    'Backtracking': 5,
    'Graphs': 6
}


export const ProblemsState = selector({
    key: "ProblemsState",
    get: ({get}) => {
        const filters = get(ProblemsFiltersState)
        return problems.filter((problem) => {
            return filters.categories.includes(problem.category) && 
                filters.difficulties.includes(problem.difficulty)
        }).sort((problem1, problem2) => {
            if (filters.orderBy === "Difficulty") {
                return difficultySortValues[problem1.difficulty] - difficultySortValues[problem2.difficulty]
            } else if (filters.orderBy === "Category") {
                return categorySortValues[problem1.category] - categorySortValues[problem2.category]
            }
            return 0
        })
    }
})

export const SelectedProblemState = selectorFamily({
    key: 'SelectedProblemState',
    get: ({user, pathName}) => async () => {
        console.log({pathName, problems})
        const {id} = problems.find(problem => problem.pathName === pathName)
        const response = await axios.get(`https://codingprobs-backend.herokuapp.com/problems?problemID=${id}`)
        const data = response.data
        if (user) {
            const userCodeSnapshot = await getUserCode(user.uid, id)
            const userCode = userCodeSnapshot.val()
            if (userCode) {
                data.startCode = userCode.code
            }
        }
        return data
    }
})