// @flow
import { useCallback } from "react";
import {useSetRecoilState, useResetRecoilState} from 'recoil'
import { WalkthroughStepsState, WalkthroughStepState } from "../state/WalkthroughState";
import { SlideshowState, SlideshowStepState } from "../state/SlideshowState";
import { CodeSolutionState } from "../state/CodeState";


export default function useDevEnvironment(): ((problemState: empty) => void) {
    const setWalkthrough = useSetRecoilState(WalkthroughStepsState)
    const resetWalkthrough = useResetRecoilState(WalkthroughStepsState)
    const resetWalkthroughStep = useResetRecoilState(WalkthroughStepState)
    const setSlideshow = useSetRecoilState(SlideshowState)
    const resetSlideshow = useResetRecoilState(SlideshowState)
    const resetSlideshowStep = useResetRecoilState(SlideshowStepState)
    const setCodeSolution = useSetRecoilState(CodeSolutionState)

    return useCallback((problemState) => {
        if (problemState.state === "hasValue") {
            setCodeSolution(problemState.contents.startCode)
            if (problemState.contents.pythonWalkthrough) {
                setWalkthrough(problemState.contents.pythonWalkthrough)
                resetWalkthroughStep()
            } else {
                resetWalkthrough()
                resetWalkthroughStep()
            }
            if (problemState.contents.slideshow) {
                setSlideshow(problemState.contents.slideshow)
                resetSlideshowStep()
            } else {
                resetSlideshow()
                resetSlideshowStep()
            }
        }
    }, [
        setCodeSolution,
        setWalkthrough, 
        setSlideshow, 
        resetSlideshowStep, 
        resetWalkthroughStep, 
        resetWalkthrough, 
        resetSlideshow,
    ])
}