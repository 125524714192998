// @flow
import * as React from 'react'
import { Box, Text } from "@chakra-ui/react";
import { useRecoilValue } from 'recoil'
import { SlideshowStepState, SlideshowState } from "../../state/SlideshowState";
import { useMemo } from 'react'

export default function SlideshowConsoleContent(): React.Element<typeof Box> {
    const slideshowStep = useRecoilValue(SlideshowStepState)
    const slideshow = useRecoilValue(SlideshowState)
    
    const step = useMemo(() => {
        if (slideshowStep >= 0 && slideshow.length > slideshowStep) {
            return slideshow[slideshowStep].text
        }
        return ''
    }, [slideshowStep, slideshow])

    return (
        <Box>
            <Text fontSize="2xl" >{step}</Text>
        </Box>
    )
}