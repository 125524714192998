// @flow
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import {
    useParams
  } from "react-router-dom";
import axios from 'axios'
import uniqid from 'uniqid';
import { useAuthState } from "react-firebase-hooks/auth";
import { CodeSolutionState, CodeTestState } from '../state/CodeState'
import { TestsState } from '../state/TestsState';
import { auth, setProblemSolved } from '../auth/firebase';
import { SolvedProblems } from "../state/ProfileState";
import { getProblemFromPathName } from '../data/problems';

const ENDPOINT = "https://algorocket-hopper-compiler.com/tester"

export default function useCodeTester(): { testCode: () => void } {
    const codeSolution = useRecoilValue(CodeSolutionState)
    const { problemPath } = useParams()
    const setCodeTestState = useSetRecoilState(CodeTestState)
    const setTestsState = useSetRecoilState(TestsState)
    const [user] = useAuthState(auth);
    const [solvedProblems, setSolvedProblems] = useRecoilState(SolvedProblems)

    const testCode = () => {
        setCodeTestState({
            loading: true,
            output: ''
        })

        let userID = user ? user.uid : null
        if (!userID) {
            let guestID = localStorage.getItem('guestID')
            if (!guestID) {
                guestID = uniqid('guest-')
                localStorage.setItem('guestID', guestID)
            }
            userID = guestID
        }
        const problem = getProblemFromPathName(problemPath)
        axios({
            method: 'POST',
            url: ENDPOINT,
            timeout: 10 * 1000,
            data: {
              code: codeSolution,
              problemID: problem.id,
              userID
            }
          }).then((response) => {
            console.log({response})
            const {rawOutput, testResults} = response.data
            setCodeTestState({
                loading: false,
                output: rawOutput
            })
            setTestsState(testResults)
            if (user) {
                if (testResults.every(result => result === 'PASS')) {
                    setProblemSolved(user.uid, problem.id)
                    setSolvedProblems(solvedProblems.concat([problem.id]))
                }
            }
        }).catch((error) => {
            setCodeTestState({
                loading: false,
                output: error.message
            })
        })
    }

    return {
        testCode
    } 
}