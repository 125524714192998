// @flow
import * as React from 'react'
import { Box, Button, HStack } from "@chakra-ui/react"
import { useCallback } from "react"
import { useRecoilValue } from 'recoil'
import { CodeRunState, CodeTestState } from "../../state/CodeState"
import TestResults from "../TestResults"
import useCodeRunner from "../../hooks/RunCodeHook"
import useCodeTester from "../../hooks/TestCodeHook"

const RUN_SECTION = 'RUN'
const SUBMIT_SECTION = 'SUBMIT'

export default function EditorConsoleHeader({
    showConsole,
    hideConsole,
    isHidden,
    section,
    setSection
}: {
    showConsole: () => void,
    hideConsole: () => void,
    isHidden: boolean,
    section: 'RUN' | 'SUBMIT',
    setSection: ('RUN' | 'SUBMIT') => void
}) : React.Element<typeof Box> {

    const codeRunState = useRecoilValue(CodeRunState)
    const codeSubmitState = useRecoilValue(CodeTestState)
    const {runCode} = useCodeRunner()
    const {testCode} = useCodeTester()

    const run = useCallback(() => {
        setSection(RUN_SECTION)
        showConsole()
        runCode()
    }, [runCode, showConsole, setSection])

    const submit = useCallback(() => {
        setSection(SUBMIT_SECTION)
        showConsole()
        testCode()
    }, [testCode, showConsole, setSection])

    return (
        <Box display="flex" justifyContent={"space-between"} marginBottom={3}>
            <HStack>
                <Button 
                    colorScheme={"blue"} 
                    onClick={run}
                    isLoading={codeRunState.loading}
                >
                    Run Code
                </Button>
                <Button 
                    colorScheme={"green"} 
                    onClick={submit}
                    isLoading={codeSubmitState.loading}
                >
                    Run Tests
                </Button>
                <TestResults />
            </HStack>
            <Button hidden={isHidden} onClick={hideConsole}>X</Button>
        </Box>
    )
}