// @flow
import * as React from 'react'
import {
    Modal,
    Box, 
    useColorMode, 
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Radio, 
    RadioGroup,
    Button,
    Heading
  } from "@chakra-ui/react"
import { useRecoilState } from "recoil";
import { EditorFontSizeOptions, EditorFontSizeState } from '../state/SettingsState';
import { ProblemsFiltersState, orderByOptions } from "../state/ProblemsState";


export default function SettingsModal(
    {
        isOpen, 
        onClose
    }: {
        isOpen: boolean,
        onClose: () => void
    }): React.Element<typeof Modal> {
        
    const { colorMode, toggleColorMode } = useColorMode()
    const [filters, setFilters] = useRecoilState(ProblemsFiltersState)
    const [fontSize, setEditorFontSize] = useRecoilState(EditorFontSizeState)

    const onOrderingChanged = (value) => {
        localStorage.setItem('orderBy', value)
        setFilters((prevState) => {
            return {
                ...prevState,
                orderBy: value
            }
        })
    }

    const onFontSizeChanged = (value) => {
        localStorage.setItem('editorFontSize', value)
        setEditorFontSize(parseInt(value))
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Settings</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Heading size="m"> Order Problems By </Heading>
                    <RadioGroup 
                        value={filters.orderBy} 
                        defaultValue="Random" 
                        onChange={onOrderingChanged}
                    >
                        <Box display="flex" width="100%" flexWrap="wrap" style={{gap: 7}}>
                            {
                                orderByOptions.map((category) => {
                                    return <Radio value={category}>{category}</Radio>
                                })
                            }
                        </Box>
                    </RadioGroup>
                    <Heading size="m"> Editor Font Size </Heading>
                    <RadioGroup 
                        value={fontSize} 
                        defaultValue={14} 
                        onChange={onFontSizeChanged}
                    >
                        <Box display="flex" width="100%" flexWrap="wrap" style={{gap: 7}}>
                            {
                                EditorFontSizeOptions.map((fs) => {
                                    return <Radio value={fs}>{fs}</Radio>
                                })
                            }
                        </Box>
                    </RadioGroup>
                    <Heading size="m"> Theme </Heading>
                    <RadioGroup value={colorMode} defaultValue="dark" onChange={toggleColorMode}>
                        <Box display="flex" width="100%" flexWrap="wrap" style={{gap: 7}}>
                            <Radio value={"dark"}>Dark</Radio>
                            <Radio value={"light"}>Light</Radio>
                        </Box>
                    </RadioGroup>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}