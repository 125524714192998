// @flow
import * as React from 'react'
import { useMemo } from "react";
import { Badge } from "@chakra-ui/react"


export default function DifficultyBadge({difficulty}: {
    difficulty: "Easy" | "Medium" | "Hard"
}): React.Element<typeof Badge> {

    const colorScheme = useMemo(() => {
        let colorScheme = "gray"
        if (difficulty === "Easy") {
            colorScheme = "green"
        }
        if (difficulty === "Medium") {
            colorScheme = "orange"
        }
        if (difficulty === "Hard") {
            colorScheme = "red"
        }

        return colorScheme
    }, [difficulty])

    return <Badge colorScheme={colorScheme}>{difficulty}</Badge>
}