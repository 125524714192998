// @flow
import * as React from 'react'
import { Textarea } from "@chakra-ui/react"
import { useRecoilValue } from 'recoil'
import { CodeRunState, CodeTestState } from "../../state/CodeState"

export default function EditorConsoleContent({section}: {section: string}): React.Element<typeof Textarea> {
    const codeRunState = useRecoilValue(CodeRunState)
    const codeSubmitState = useRecoilValue(CodeTestState)

    return (
        <Textarea 
            placeholder="No output" 
            height="70%" 
            isReadOnly={true} 
            minHeight="10px"
            style={{resize: "none"}} 
            focusBorderColor={"gray"}
            value={section === 'RUN' ?  codeRunState.output : codeSubmitState.output}
    /> 
    )
}