// @flow

import type { RecoilState } from "recoil"
import { atom } from 'recoil'

export const CodeSolutionState: RecoilState<?string> = atom({
    key: 'CodeSolutionState',
    default: null
})

export const CodeRunState: RecoilState<{
    loading: boolean,
    output: string
}> = atom({
    key: 'CodeRunState',
    default: {
        loading: false,
        output: ''
    }
})

export const CodeTestState: RecoilState<{
    loading: boolean,
    output: string
}> = atom({
    key: 'CodeTestState',
    default: {
        loading: false,
        output: ''
    }
})