// @flow
import * as React from 'react'
import { Box, Button, HStack, Text} from "@chakra-ui/react"
import { useRecoilState, useRecoilValue } from 'recoil'
import { SlideshowStepState, SlideshowState } from "../../state/SlideshowState";
import { useCallback, useMemo } from 'react'

export default function SlideshowConsoleHeader(): React.Element<typeof Box> {
    const [slideshowStep, setSlideshowStep] = useRecoilState(SlideshowStepState)
    const slideshow = useRecoilValue(SlideshowState)

    const onPreviousClicked = useCallback(() => {
        setSlideshowStep(prev => prev - 1)
    }, [setSlideshowStep])

    const onNextClicked = useCallback(() => {
        setSlideshowStep(prev => prev + 1)
    }, [setSlideshowStep])

    const previousButtonDisabled = useMemo(() => {
        return slideshowStep === 0
    }, [slideshowStep])

    const nextButtonDisabled = useMemo(() => {
        return slideshowStep === slideshow.length - 1
    }, [slideshowStep, slideshow])

    return (
        <Box display="flex" justifyContent="space-between">
            <HStack>
                <Button 
                    onClick={onPreviousClicked}
                    isDisabled={previousButtonDisabled}
                >
                    Previous
                </Button>
                <Button 
                    onClick={onNextClicked}
                    isDisabled={nextButtonDisabled}
                >
                    Next
                </Button>
            </HStack>
            {
                slideshowStep >= 0 ?
                <Text>{`${slideshowStep + 1} / ${slideshow.length}`}</Text> :
                null
            }
        </Box>
    )
}