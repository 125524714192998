// @flow
import * as React from 'react'
import { Box, useColorMode } from "@chakra-ui/react";
import MonacoEditor from "@monaco-editor/react";
import { EditorFontSizeState } from '../state/SettingsState';
import { useRecoilValue } from 'recoil'

export default function Editor({value, onChange, readOnly}: {
    value: ?string,
    onChange: (string) => void,
    readOnly: boolean
}): React.Element<typeof Box> {
    const { colorMode } = useColorMode()
    const fontSize = useRecoilValue(EditorFontSizeState)

    return (
        <Box flex={10} overflowY="hidden">
            <MonacoEditor 
                width="100%" 
                height="100%" 
                defaultLanguage="python" 
                defaultValue={value}
                theme={colorMode === "dark" ? "vs-dark" : "light"}
                value={value}
                onChange={onChange}
                options={{
                    readOnly,
                    fontSize
                }}
            />
        </Box>
    )
}