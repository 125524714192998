// @flow
import * as React from "react";
import LeftSideMenu from "./components/LeftSideMenu";
import DevEnvironment from "./components/DevEnvironment";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Home from './pages/Home'

import './App.css'

function App(): React.Element<"div"> {
  return (
    <div className="App">
      <Router>
        <LeftSideMenu />
        <Switch>
          <Route exact path="/" children={<Home />} />
          <Route path="/:problemPath" children={<DevEnvironment />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
