// @flow
import * as React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Heading,
    Spinner,
    Image
  } from "@chakra-ui/react"
import { useMemo } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, signInWithGoogle, logout } from '../auth/firebase';


export default function ProfileModal(
    {
        isOpen,
        onClose,
    }: {
        isOpen: boolean,
        onClose: () => void
    }): React.Element<typeof Modal> {
    const [user, loading, error] = useAuthState(auth);

    const content = useMemo(() => {
        if (loading) {
            return <Spinner />
        }

        if (user) {
            return (
                <Box display="flex" flexDirection="column" alignItems="center">
                    {
                        user.photoURL ? 
                        <Image src={user.photoURL} borderRadius="full" marginBottom={5}/> :
                        <Box bgColor="gray.300" boxSize="40" borderRadius="full" />
                    }
                    <Heading>{user.displayName}</Heading>
                    <Heading size="md"> {user.email} </Heading>
                </Box>
            )
        }

        if (error) {
            return <Heading>Error Getting Authentication</Heading>
        }
        
        return <Button onClick={signInWithGoogle}>Sign In With Google</Button>
    }, [loading, user, error])

    const onLogOut = () => {
        onClose()
        logout()
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Profile</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {content}
                </ModalBody>

                <ModalFooter>
                    <Button variant="ghost" onClick={onLogOut}>Log Out</Button>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}